.img-full {
  width: 100%;
}

.margin-0 {
  margin-bottom: 0 !important;
}

.margin-20 {
  margin-bottom: 20px !important;
}

.margin-40 {
  margin-bottom: 40px !important;
}

.margin-60 {
  margin-bottom: 60px !important;
}
.padding-0 {
  padding-top: 0px !important;
}

.padding-5 {
  padding-top: 5px !important;
}

.padding-20 {
  padding-top: 20px !important;
}

.padding-40 {
  padding-top: 40px !important;
}

.padding-60 {
  padding-top: 60px !important;
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.btn-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap!important;
    max-width: 100%;
}

.f-w-400 {
  font-weight: 400!important;
}
.f-w-200 {
  font-weight: 200!important;
}

@media (min-width: 768px) {
   .btn-truncate {
    max-width: 120px;
  }

}

@media (min-width: 992px) {
   .btn-truncate {
     max-width: 200px;
  }

}
