.navbar .open > .dropdown-menu{
  border:inherit;
}
.navbar {

  ul li .dropdown-menu {
    background: $brand-primary !important;
     & li a {
      color:#fff;
      text-transform: uppercase;
      border-bottom:none;
      text-decoration: none;
    }
    &>li>a:hover,& li a:hover,&>li>a:focus,& li a:focus {
      background-color:lighten($brand-primary, 10%);
      color: white;
    }
  }
}

.open > .dropdown-menu{border:1px solid #dbdbdb;}
.dropdown-menu {
  padding:0;

  li a {
    border-bottom:1px solid #dbdbdb;
    display: block;
    clear: both;
    font-weight: normal;
    line-height: 1.42857143;
    white-space: nowrap;
    font-size: 16px;
    padding:12px 15px;
    text-decoration: none;

  }

  li a:not( .btn ):hover,
  li a:not( .btn ):focus {
    background-color: $brand-primary;
    color: white;
  }

  li:first-of-type a {
    border-radius: 4px 4px 0 0;
  }
  li:last-of-type a {
    border-radius: 0 0 4px 4px;
    border: none;
  }
}

.navbar-inverse .dropdown-menu>li>a,
.navbar-inverse .dropdown-menu li a {
  font-size: 12px;
  font-weight:500;
  padding: 8px 15px !important;
}
.submenu .dropdown-header {
  color: white;
  padding: 0 15px !important;
  margin: 8px 0;
  opacity: .7;
  font-weight: 700;
  font-size: 24px;
}
.dropdown-menu .divider {
  background-color: rgba(255,255,255,.4) !important;
}

.dropdown-menu>li>a:hover,
.dropdown-menu li a:hover,
.dropdown-menu>li>a:focus,
.dropdown-menu li a:focus {
    color: #fff;
}

@media (max-width: 767px) {

  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
      color: #fff;
      padding: 12px 15px;
      &:hover,&:focus {
        color: #fff;
    }
  }

}
