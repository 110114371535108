html,
body {
  font-size: 16px;
  height: 100%;
  max-width: 100%;
  // overflow-x: hidden; // Se comenta porque rompe el screenshot en pantalla completa completa
}

body {
  background: $body-bg;
  color: $text-color;
  width: 100%;
}

hr {
  border-top: 1px solid $gray-light;
  margin: 30px 0;

  &.hr-lg {
    margin: 60px 0;
  }
  &.hr-sm {
    margin: 15px 0;
  }
}

.social-share {
  a {
    font-size: 30px;
  }
}

.inline {
  display: inline-block;
}

.img-rounded {
  border-radius: 4px;
}

.element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  height: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  position: static !important;
  clip: auto;
  overflow: visible;
  height: auto;
}

section {
  padding: 40px 0;

  &.section-no-spaced {
    padding: 0;
  }
  &.section-sm {
    padding: 20px 0;
  }
  &.section-lg {
    padding: 80px 0;
  }
}

.icon-item {
  margin-bottom: 20px;

  i {
    margin-bottom: 0.3em;
    font-size: 4.5em;
    color: $brand-secondary;
    height: 90px;
  }

  h1, h2, h3, h4, h5, h5 {
    margin-top: 0 !important;
    margin-bottom: 0.2em;
  }

  h3 {
    font-size: 1.6em;
  }
}

// Fix para Argentina.gob.ar, idealmente desaparece cuando actualice drupal
h1.icon-item i {
  font-size: 2em;
}

aside {
  section {
    float: left;
    margin-bottom: 24px;
    width: 100%;
  }
}

// Paleta principal

.bg-primary {
  background-color: $brand-primary !important;
}
.bg-success {
  background-color: $brand-success !important;
 }

.bg-info {
  background-color: $brand-info !important;
}
.bg-danger {
  background-color: $brand-danger !important;
}

.bg-warning {
  background-color: $brand-warning !important;
}
.bg-gray {
  background-color: $gray-lighter !important;
}
.bg-white {
  background-color: $white !important;
}
.bg-muted {
  background-color: $gray !important;
}
// Paleta extendida

.bg-fucsia {
  background-color: $brand-fucsia !important;
}
.bg-arandano {
  background-color: $brand-arandano !important;
}
.bg-uva {
  background-color: $brand-uva !important;
}
.bg-cielo {
  background-color: $brand-cielo !important;
}
.bg-verdin {
  background-color: $brand-verdin !important;
}
.bg-lima {
  background-color: $brand-lima !important;
}
.bg-maiz {
  background-color: $brand-maiz !important;
}
.bg-tomate {
  background-color: $brand-tomate !important;
}

.bg-primary, 
.bg-success, 
.bg-info,
.bg-danger,
.bg-warning,
.bg-fucsia,
.bg-arandano,
.bg-uva,
.bg-cielo,
.bg-verdin,
.bg-lima,
.bg-maiz,
.bg-tomate {
  h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #FFF !important;
    small,
    .small {
      color: #FFF;
    }
  }  

  .panel-default:not(:hover),
  .panel-disabled  {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      color: $text-color !important;
      small,
      .small {
        color: $text-color;
      }
    }
    p {
      color: $text-color !important;
    } 
    time {
      color: $text-color !important;
    }              
  }

  .alert {
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      color: $gray-dark !important;
      small,
      .small {
        color: $gray-dark;
      }
    }
    p {
      color: $gray-dark !important;
    }  
    a {
      color: $brand-primary !important;
      &:hover,
      &:focus,
      &:active {
        color: $link-hover-color!important;
      }
    }          
  }  

  .dropdown-menu {
    > li > a {
      color: $gray-dark !important;
      &:hover,
      &:focus {
        color: #FFF !important;
      }
    }          
  }

   p,
   a,
   ul,
   ol, 
   dl, 
   blockquote, 
   blockquote small,
   blockquote footer, 
   blockquote p span, 
   table, 
   label {
    color: #FFF !important;
   }  

}


a.bg-primary,
a.bg-success,
a.bg-info,
a.bg-warning,
a.bg-danger {
  color: #FFF;
  display: block;
  &[href^="/"] {
    padding: 6px 0;
  }
  &:hover {
    opacity: 0.9;
    text-decoration: underline;
  }

  p {
    font-size: 16px;
  }
}

.text-primary {
  color: $brand-primary !important;
}
.text-secondary {
  color: $brand-secondary !important;
}
.text-success {
  color: $brand-success !important;
}
.text-info {
  color: $brand-info !important;
}
.text-warning {
  color: $brand-warning !important;
}
.text-danger {
  color: $brand-danger !important;
}
.text-black {
  color: $black !important;
}
.text-white {
  color: $white !important;
}
.text-muted {
  color: $gray !important;
}


//
.text-fucsia {
  color: $brand-fucsia !important;
}
.text-arandano {
  color: $brand-arandano !important;
}
.text-uva {
  color: $brand-uva !important;
}
.text-cielo {
  color: $brand-cielo !important;
}
.text-verdin {
  color: $brand-verdin !important;
}
.text-lima {
  color: $brand-lima !important;
}
.text-maiz {
  color: $brand-maiz !important;
}
.text-tomate {
  color: $brand-tomate !important;
}

.nav > li > a {
  text-decoration: none;
}

.page-sidebar {

  margin-bottom: 48px;
  padding: 0;

  li {
    font-size: 18px;
  }

  a {
    display: block;
    padding: 5px 0;
    text-decoration: none;
      &:hover {
        text-decoration: none;
      }
  }

  .nav {
    font-weight: 500;
    text-transform: none;
  }

  .btn-title {
    border: medium none;
    border-radius: 4px;
    color: #666;
    // cursor: pointer;
    display: inline-block;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 8px;
    margin-top: 6px;
    text-align: left;
    text-shadow: none;
    vertical-align: middle;
    white-space: normal;
    width: 100%;
  }


  .nav-pills li {

    a {
      color: #606060;
    }

    &.active > a,
    > a:hover {
      background-color: $gray-lighter;
      border-color: $gray-lighter;
    }

    &.btn-title > a {
      padding: 10px 20px;
    }

    &.disabled > a {
      color: $gray-light;

      &:hover {
        border-color: $gray-lighter;
      }
    }

    > a {
      padding: 13px 20px;
      border-radius: 4px;

      &:hover {
        border-color: $gray-lighter;
      }

      &:visited {
        color: $link-visited;
      }
    }

    &.active > a,
    &.active > a:hover,
    &.active > a:focus {
      background-color: $brand-primary;
      border: 0;
      color: $body-bg;

    }

    ul {
      padding-left: 0px;
      list-style: none;

      li {

        a {
        font-size: 16px;
        &:before {
          content: '-';
          display: inline-block;
          margin-right: 15px;
          color: $gray-light;
        }
      }

        ul li a {
          &:before {
            margin-left: 10px;
          }
        }      

      }


      li a {
        font-size: 16px;
        &:before {
          content: '-';
          display: inline-block;
          margin-right: 15px;
          color: $gray-light;
        }
      }
    }
  }



  .nav > li > a:hover,
  .nav > li > a:focus {
    background-color: $gray-lighter;
    text-decoration: none;
  }


  .badge {
    background-color: transparent;
    color: #606060;
    font-size: 16px;
    font-weight: 500;
  }

}

dd {
  margin-bottom: 15px;
}

.ribbon {
  display: inline-block;
  font-weight: 500;
  margin: 0 40px 10px 0;
  padding: 11px 0px;
  text-transform: uppercase;

  i {
    font-size: 1.5em;
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
}

.well {
  border-color: $gray-light;
  box-shadow: none;
  margin: 30px 0;
}

.credits {
  p {
    color: $gray;
    font-family: $font-family-sans-serif;
    font-size: 13px;
    line-height: 15px;
    margin-top: 40px;
  }
}

.circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
}

@media screen and (max-width: 767px) {

  h2 {
    font-size: 2.6em;
  }

  h3 {
    font-size: 32px;
  }

  article {
    h1 {
      &.icon {
        font-size: 8em;
        line-height: 1.5;
        text-align: center;
      }
    }
  }

}

@media screen and (min-width: 768px) {

  .open {
    .submenu {
      display: table;

      >div {
        display: table-cell;
      }
    }
  }

  .submenu {
    li {
      a {
        padding-right: 60px !important;
      }
    }
  }

}


@media screen and (max-width: 767px) {

  blockquote {
    text-align: center;

    img {
      margin: 0 auto;
      padding: 0 45px 16px;
    }
  }

}

#search_wrapper {
  display: none;
  margin-top: 5px;
  padding-right: 0;

  .glyphicon-search {color: $brand-primary;}

  span {
    &.bg-primary {
      border-radius: 4px;
      float: left;
      margin-right: 20px;
      margin-top: 15px;
      padding: 3px 7px;
    }
  }

  input {font-size: 1.1em;}

  .input-group-addon,
  .form-control {
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    border-top: 0;
  }

  .stylish-input-group {

    .input-group-addon {
      background: $white !important;
      padding: 10px 16px;
    }

    .form-control {
      border-color: $gray-border;
      box-shadow: 0 0 0;
    }

    button {
      background: transparent;
      border: 0;
    }
  }
}

.additional_data {
  padding-top: 0;
  text-align: right;
}

.title-description {
  .additional_data {
    padding-top: 15px;
  }

}

.state-loading, .icon-load.progress-disabled {
  position: relative;

  &:before {
    -webkit-animation: loader .6s linear;
    -webkit-animation-iteration-count: infinite;
    animation: loader .6s linear;
    animation-iteration-count: infinite;
    border-color: $gray $gray-background $gray-background;
    border-radius: 600rem;
    border-style: solid;
    border-width: .2em;
    box-shadow: 0 0 0 1px transparent;
    content: '';
    height: 2.2585em;
    left: 50%;
    margin: -1.12925em 0 0 -1.12925em;
    position: absolute;
    top: 50%;
    width: 2.2585em;
    z-index: 5;
  }

  &:after {
    background-color: rgba(204,204,204,.7);
    border-radius: 4px;
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 4;
  }

}

@keyframes loader {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)}
  }

.fa-500px:before,
.fa-adn:before,
.fa-amazon:before,
.fa-angellist:before,
.fa-bandcamp:before,
.fa-behance:before,
.fa-behance-square:before,
.fa-bitbucket:before,
.fa-bitbucket-square:before,
.fa-bitcoin:before,
.fa-black-tie:before,
.fa-btc:before,
.fa-buysellads:before,
.fa-cc-amex:before,
.fa-cc-diners-club:before,
.fa-cc-discover:before,
.fa-cc-jcb:before,
.fa-cc-mastercard:before,
.fa-cc-paypal:before,
.fa-cc-stripe:before,
.fa-cc-visa:before,
.fa-codepen:before,
.fa-codiepie:before,
.fa-connectdevelop:before,
.fa-contao:before,
.fa-css3:before,
.fa-dashcube:before,
.fa-delicious:before,
.fa-deviantart:before,
.fa-digg:before,
.fa-dribbble:before,
.fa-dropbox:before,
.fa-drupal:before,
.fa-edge:before,
.fa-eercast:before,
.fa-empire:before,
.fa-envira:before,
.fa-etsy:before,
.fa-expeditedssl:before,
.fa-fa:before,
.fa-firefox:before,
.fa-first-order:before,
.fa-font-awesome:before,
.fa-fonticons:before,
.fa-fort-awesome:before,
.fa-forumbee:before,
.fa-foursquare:before,
.fa-free-code-camp:before,
.fa-ge:before,
.fa-get-pocket:before,
.fa-gg:before,
.fa-gg-circle:before,
.fa-git:before,
.fa-git-square:before,
.fa-gittip:before,
.fa-glide:before,
.fa-glide-g:before,
.fa-gratipay:before,
.fa-grav:before,
.fa-hacker-news:before,
.fa-houzz:before,
.fa-imdb:before,
.fa-ioxhost:before,
.fa-joomla:before,
.fa-jsfiddle:before,
.fa-lastfm:before,
.fa-lastfm-square:before,
.fa-leanpub:before,
.fa-linode:before,
.fa-maxcdn:before,
.fa-meanpath:before,
.fa-medium:before,
.fa-meetup:before,
.fa-mixcloud:before,
.fa-modx:before,
.fa-odnoklassniki:before,
.fa-odnoklassniki-square:before,
.fa-opencart:before,
.fa-openid:before,
.fa-optin-monster:before,
.fa-pagelines:before,
.fa-paypal:before,
.fa-pied-piper:before,
.fa-pied-piper-alt:before,
.fa-pied-piper-pp:before,
.fa-product-hunt:before,
.fa-qq:before,
.fa-quora:before,
.fa-ra:before,
.fa-ravelry:before,
.fa-rebel:before,
.fa-reddit:before,
.fa-reddit-alien:before,
.fa-reddit-square:before,
.fa-renren:before,
.fa-resistance:before,
.fa-scribd:before,
.fa-sellsy:before,
.fa-share-alt:before,
.fa-share-alt-square:before,
.fa-shirtsinbulk:before,
.fa-simplybuilt:before,
.fa-skyatlas:before,
.fa-slack:before,
.fa-slideshare:before,
.fa-snapchat:before,
.fa-snapchat-ghost:before,
.fa-snapchat-square:before,
.fa-soundcloud:before,
.fa-spotify:before,
.fa-stack-exchange:before,
.fa-stack-overflow:before,
.fa-steam:before,
.fa-steam-square:before,
.fa-stumbleupon:before,
.fa-stumbleupon-circle:before,
.fa-superpowers:before,
.fa-telegram:before,
.fa-tencent-weibo:before,
.fa-themeisle:before,
.fa-trello:before,
.fa-tripadvisor:before,
.fa-twitch:before,
.fa-viacoin:before,
.fa-viadeo:before,
.fa-viadeo-square:before,
.fa-vine:before,
.fa-vk:before,
.fa-wechat:before,
.fa-weibo:before,
.fa-weixin:before,
.fa-wikipedia-w:before,
.fa-wordpress:before,
.fa-wpbeginner:before,
.fa-wpexplorer:before,
.fa-wpforms:before,
.fa-xing:before,
.fa-xing-square:before,
.fa-y-combinator:before,
.fa-y-combinator-square:before,
.fa-yahoo:before,
.fa-yc:before,
.fa-yc-square:before,
.fa-yelp:before,
.fa-yoast:before {
    content: "";
}


