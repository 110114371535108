textarea {
  resize: vertical;
}
label {
  font-weight: 400;
  &.control-label {font-weight: 700;}
  &.label-block {display: block;}
}

.form-group {
  margin-bottom: 30px;
}

.form-control {
  color: $text-color;
  font-size: 18px;
  height: 54px;
  padding: 6px 8px;
  border: solid 1px $gray-light;
  // text-indent: 6px;

  &[disabled],
  &[readonly] {
    background-color: $gray-lighter;
  }
}

fieldset {
  &.framed {
    padding: 10px 30px;
    border-radius: 4px;
    margin-bottom: 30px;
    border: 1px solid $gray-light;
  }

  &[disabled] {
    .form-control {
      background-color: $gray-lighter;
    }
 }
}

.input-lg,
select.input-lg {
  font-size: 20px;
  font-weight: 400;
  height: 65px;
  padding: 10px 20px;
}

.input-sm {
  height: 30px;
}

select.input-sm {
  padding-top: 0;
  padding-bottom: 0;  
}


.input-group-lg > .form-control,
.input-group-lg > .input-group-addon,
.input-group-lg > .input-group-btn > .btn {
  border-radius: 4px;
  font-size: 20px;
  height: 65px;
  line-height: 1.3333333;
  padding: 10px 20px;
}

.input-group-lg > .input-group-btn > .btn .glyphicon {
  font-size: 1.4em;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child > .btn,
.input-group-btn:first-child > .btn-group > .btn,
.input-group-btn:first-child > .dropdown-toggle,
.input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-addon {
  &:first-child {
    border-right: 0;
  }
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:last-child > .btn,
.input-group-btn:last-child > .btn-group > .btn,
.input-group-btn:last-child > .dropdown-toggle,
.input-group-btn:first-child > .btn:not(:first-child),
.input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group > .form-item > input {
  border-radius: 4px 0 0 4px !important;
}

.input-group-shadow {
  border-radius: 4px;
  box-shadow: $shadow;

  .form-control {
    border: 0;
  }

  .btn {
    margin: 0;
  }
}

input {
  &[type='text'] {
    @include placeholder {
      color: $gray;
    }
  }
}

.form-complete {
  input {
    &[type='text'] {
      @include placeholder {
        color: $text-color;
      }
    }
  }
}


.inputfile {
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: .1px;
  z-index: -1;

  &:focus {

    + label {
      background-color: #e0e2e2;
    }

  }

  + label {
    background-color: #ececec;
    border: 2px solid #e0e2e2;
    display: inline-block;
    font-weight: bold;
    padding: 4px 15px;
    transition: .3s ease-in-out;

    &:hover {
      background-color: #e0e2e2;
    }
  }
}

.has-success {
  .form-control {
    border-color: #43894E;
  }
}

.has-warning {
  .form-control {
    border-color: #C1994D;
  }
}

.has-error {
  .form-control {
    border-color: #BB4945;
  }
}

.input-group-btn {
  .btn-default {
    background-color: #838383;
    border: 1px solid $gray-light;
    padding: 15px 25px;
  }
}

.open > .dropdown-toggle.btn-default{color: #fff !important;}

.has-feedback {
  input ~ .form-control-feedback {
    top: 38px;
  }
  input.input-sm ~ .form-control-feedback {
    top: 28px;
  }
  input.input-lg ~ .form-control-feedback {
    top: 36px;
  }
}

/* Chosen dropdowns */

.chosen-container {
  background-color: #fff;
  font-size: 18px;

}

.chosen-container.chosen-container-single .chosen-single {
  background: none;
  height: 54px;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.075);
  font-weight: 400;
  color: #111;
  line-height: 36px;
}

.chosen-container.chosen-container-single .chosen-single div b {
  margin-top: 30%;
  height: 70%;
}
.chosen-container.chosen-container-single.chosen-with-drop .chosen-single {
  background: none;
  box-shadow: inset none;
}

.chosen-container.chosen-container-multi .chosen-choices {
  background: none;
  height: 54px;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.075);
  font-weight: 400;
  color: #111;  
  line-height: 36px;
}
.chosen-container.chosen-container-multi.chosen-with-drop .chosen-choices {
  border-radius: 4px 4px 0 0;
  border-color: #aaa;
}

.chosen-container .chosen-results li {
  line-height: 20px;
}
.chosen-container .chosen-results li.group-result {
    font-weight: 400;
}

.chosen-container.chosen-container-single.chosen-with-drop .chosen-results li.highlighted,
.chosen-container.chosen-container-multi.chosen-with-drop .chosen-results li.highlighted {
  background: #4265bc;
}
.chosen-container.chosen-container-single.chosen-with-drop .chosen-results li.group-result,
.chosen-container.chosen-container-multi.chosen-with-drop .chosen-results li.group-result {
  text-shadow: none;
}
