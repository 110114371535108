article {
  > div > header {
    border-bottom: solid 1px $gray-light;
    margin-bottom: 48px;
    padding-bottom: 12px;
  }

  a {
    text-decoration: underline;
    &.btn {text-decoration: none;}

    &:hover,
    &:focus {text-decoration: underline;}
  }

  p {
    line-height: 1.6;
    margin-bottom: 24px;
  }

  ul {
    margin-bottom: 24px;
    margin-top: 24px;

    &.ul-lg {
      li {
        font-size: 1.4em;
        line-height: 1.6;
      }
    }
  }

  li {
    font-size: 16px;
    line-height: 24px;
  }

  footer {
    border-top: solid 1px $gray-light;
    margin-bottom: 32px;
    margin-top: 24px;
    padding-top: 16px;

    h3 {
      font-weight: 400;
    }
  }

  .social-date {
    h4 {
      font-weight: 400;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  & h1.first,
  & h2.first,
  & h3.first,
  & h4.first,
  & h5.first,
  & h6.first {
    margin-top: 0;
  }

  @media only screen and (min-width: 1200px) {
    .overlap {
      background: $body-bg;
      border-radius: 4px;
      box-sizing: content-box;
      left: -60px;
      margin-top: -60px;
      padding: 20px 60px 0;
      position: relative;

      h1 {
        margin-top: .5em;
      }
    }
  }

}

figure {
  margin: 24px 0 32px;

  img {
    border-radius: 4px;
    margin-bottom: 16px;
    max-width: 100%;
  }

  figcaption {
    color: $gray;
    // font-size: 16px;
    margin-bottom: 24px;
    text-align: center;
  }
}


blockquote {
  border: 0;
  margin: 32px;
  padding: 0;

  cite {
    color: $gray;
  }

  p {
    font-size: 22px !important;
    line-height: 1.3;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  small,
  footer {
    color: $gray;
    font-size: 16px;
    font-style: italic;
    margin-top: 10px;
  }

  &.quote-highlighted {
    margin: 0 50px 0;
    @include position(relative);

    p {
      font-size: 1.5em !important;
      font-style: italic;
      text-align: center;

      span {

        &.quotemark-open {
          @include position(absolute, null null null -50px);
          color: $gray;
          font-size: 4em;
          font-style: normal;
          margin-top: -30px;
        }

        &.quotemark-close {
          @include position(absolute, null -40px null null);
          color: $gray;
          font-size: 4em;
          font-style: normal;
          margin-top: -50px;
        }
      }
    }

    small,
    footer {
      color: $gray;
      font-size: 20px;
      font-style: italic;
      margin-top: 10px;
    }
  }
}

.article-news {
  article {
    section {
      font-family: $font-family-serif;
    }
  }

  p {
    font-size: 18px;
    }

  li {
    font-size: 18px;
    margin-bottom: 6px;
  }
}

.title-description {
  hr {margin-bottom: 0; }
}
.related-news {margin-bottom: 70px;}

.overlap {
  h1 {
    margin-top: 60px;
  }
}


@media (min-width: 992px) {
   article li {
    font-size: 18px;
  }

}





