.list-group-item {
  border: 0;
  border-bottom: solid 1px $gray-light;
  color: $gray;
  display: block;
  font-weight: 400;
  margin-bottom: 0;
  padding: 16px 15px;
  width: 100%;

  &:last-child {
    border: 0;
  }

}

a {
  &.list-group-item {
    color: $gray-base;
    font-weight: 500;

    &:hover,
    &:focus,
    &:hover small,
    &:focus small {
      background-color: $brand-primary;
      color: $white;
    }
  }
}

.list-group-agenda {
  display: table;
  width: 100%;

  h4 {
    font-weight: 700;
    margin-top: 4px;
  }
}

.agenda-date {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  width: 56px;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 400;
  }

  h3 {
    margin-top: 0;
  }

  h4 {
    font-size: 1.2em;
    margin: 0;

    small {
      display: block;
      font-size: .9em;
      margin-top: 6px;
    }
  }

  strong {
    display: block;
    font-size: 28px;
  }
}

.agenda-small {
  width: 30px;
}

.agenda-info {
  display: table-cell;
  margin-top: -15px;
  padding-left: 15px;
  vertical-align: top;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-weight: 400;
  }

  h4 {
    font-weight: normal;
  }
}

.list-group-areas {
  h3 {
    font-weight: 700;
    margin-top: 0;
  }
}

.steps {
  .step {
    position: relative;
    min-height: 32px;
    > div:first-child {
        position: static; height: 0;
    }
    > div:last-child {
      margin-left: 32px;
      padding-left: 16px;
    }
    .circle {
      background: $gray-border;
      width: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 16px;
      position: relative;
      color: white;
      text-align: center;
    }
    .line {
      position: absolute;
      border-left: 1px solid $gray-border;
      left: 16px;
      bottom: 10px;
      top: 42px;
    }
    &:last-child .line {
      display: none;
    }
    .description {
      padding-bottom: 40px;
    }

    &:last-of-type .description {
      padding: 0;
    }
  }
  &.steps-horizontal {
    .step {
      > div:last-child {
          margin: 120px 0 0;
      }
      .circle {
        font-size: 35px;
        margin: 0 auto;
        width: 100px;
        height: 100px;
        line-height: 100px;
        border-radius: 50px;
      }      
      .line {
        border-top: 1px solid #ccc;
        border-left: none;
        left: 75%;
        top: 50px;
        width: 50%;        
      }      
    }      
  }  
}

.steps-min {
  .step {
    min-height: 16px;
    > div:last-child {
      margin-left: 16px;
      padding-left: 8px;
    }
    .circle {
      width: 16px;
      height: 16px;
      line-height: 16px;
      border-radius: 8px;
    }
    .line {
      left: 8px;
      bottom: 5px;
      top: 22px;
    }
    .description {
      padding-bottom: 20px;
    }

  }
 
}

@media screen and (max-width: 767px) {


  .steps.steps-horizontal {
    .step {
      > div:last-child {
          margin: 70px 0 0;
      }
      .circle {
        font-size: 20px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 25px;
      }      
    }      
  } 

}
