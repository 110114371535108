.overlay {
  @include position(absolute, 0 null null 0);
  background: $dark-opacity;
  filter: $dark-opacity-IE;
  height: 100%;
  width: 100%;
}

.jumbotron {
  @include position(relative, null null null null);
  background-position: center center;
  background-size: cover;
  color: $white;
  margin-bottom: 0;
  padding: 0;

  .jumbotron_bar {
    background: rgba(0,0,0,.3);
    font-size: 14px;
    padding: 16px 0;
    position: relative;
    z-index: 3;

    &.jumbotron-bar-transparent {
      background: none;
    }

    .list-inline {
      margin-bottom: 0;
      margin-top: 0;

      .dropdown {
        display: inline-block;
      }

      > li {
        &:after {
          content: '|';
          margin-left: 15px;
        }

        &:last-child:after {
          content: '';
          margin-left: 0;
        }

        > a,
        > .dropdown > a {
          color: $white;
          text-shadow: 0 2px 2px $dark-opacity;
          text-decoration: none;
          &:hover {opacity: .8;}

          .glyphicon { margin-right: 10px; }
        }
      }
    }

    .breadcrumb {
      margin: 0;
      text-shadow: 0 2px 2px $dark-opacity;

      li{
        margin-bottom: 0;

        &:first-child:after {
          content: '';
          margin: 0;
        }
        a {
          color:$white;
          font-size: 14px;
          text-decoration: none;
        }

        span {font-size: 14px;}
      }
    }
  }

  .jumbotron_body {
    @include position(relative, null null null null);
    padding: 80px 0;
    z-index: 2;

    h1, h2, h3, h4, h5, h6 {
      color: $white;
      text-shadow: 0 2px 2px $dark-opacity;
      font-weight: 700;
      margin-bottom: .3em;
      margin-top: 0;
      text-transform: none;
    }

    h1, .h1 {
      font-size: 46px;
    }

    h2, .h2 {
      font-size: 40px;
    }

    h3, .h3 {
      font-size: 34px;
    }

    h4, .h4 {
      font-size: 28px;
    }

    h5, .h5 {
      font-size: 24px;
    }

    h6, .h6 {
      font-size: 20px;
    }


    .panel {
      h1, h2, h3, h4, h5, h6 {
        text-shadow: none;
      }
    }

    p {
      font-size: 20px;
      line-height: 27px;
      text-shadow: 0 2px 2px $dark-opacity;
    }

    a:not(.btn):not(.panel) {
      color: $white;
      text-decoration: underline;

      &:hover {
        opacity: .8;
      }
    }

    .btn {
      text-shadow: none;
    }

    &.jumbotron_body-lg {
      padding: 120px 0;
    }
  }

  .dropdown-menu > li > a {
    color: $black;
  }
  .dropdown-menu li a:not(.btn):hover, .dropdown-menu li a:not(.btn):focus {
     color: $white;
  }

  &.align-top {
    background-position: center top;
  }

  &.align-bottom {
    background-position: center bottom;
  }

  &.jumbotron-pattern {
    background-repeat: repeat;
    background-size: inherit;
  }

  &.jumbotron-primary {
    background-color: $brand-primary !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }

  }
  &.jumbotron-success {
    background-color: $brand-success !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-info {
    background-color: $brand-info !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-danger {
    background-color: $brand-danger !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }

  &.jumbotron-warning {
    background-color: $brand-warning !important;
  }
  &.jumbotron-gray {
    background-color: $gray-lighter;
  }
  &.jumbotron-white {
    background-color: $white;
  }

  &.jumbotron-fucsia {
    background-color: $brand-fucsia !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-arandano {
    background-color: $brand-arandano !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-uva {
    background-color: $brand-uva !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-cielo {
    background-color: $brand-cielo !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-verdin {
    background-color: $brand-verdin !important;
    color: #FFF;

    *, a {
      color: #FFF;
    }
  }
  &.jumbotron-lima {
    background-color: $brand-lima !important;
  }
  &.jumbotron-maiz {
    background-color: $brand-maiz !important;
  }
  &.jumbotron-tomate {
    background-color: $brand-tomate !important;
    color: #FFF;

    * {
      color: #FFF;
    }
  }



  .btn-xl {
    margin-top: 16px;
  }

  @media screen and (min-width: 768px) {
    h2 {font-size: 44px; }
  }

  @media screen and (max-width: 767px) {
    @include position(relative, null null null null);

    h1 {
      font-size: 32px !important;
    }
    // min-height: 240px;
    padding: 0;
  }

}

.container {
  .jumbotron {
    border-radius: 7px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;

    .jumbotron_body {
      padding: 60px;
    }
  }
}

.jumboarticle {
  @media screen and (min-width: 768px) {

    min-height: 450px;
  }
  @media screen and (max-width: 767px) {
    min-height: 250px;
  }
}



