
//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$white:                  #fff !default;
$black:                  #111 !default;
$gray-base:              #111 !default;

$gray:                   #767676;
$gray-dark:              #444;
$gray-border:            #ccc;
$gray-hover:             #e9e9e9;
$gray-hover-light:       #f0f0f0;
$gray-background:        #F9F9F9;
$gray-light:             #ccc;
$gray-lighter:           #e9e9e9;

$brand-default:         #838383 !default;
$brand-primary:         #0695d6 !default;
$brand-primary-alt:     #0a73ae !default;
$brand-secondary:       #01bdf2 !default;
$brand-complementary:   #ef4d3d !default;
$brand-success:         #33b560 !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #fec221 !default;
$brand-danger:          #ef4d3d !default;

$brand-fucsia:          #ea1d76 !default;
$brand-arandano:        #bd1e61 !default;
$brand-uva:             #873299 !default;
$brand-cielo:           #009dda !default;
$brand-verdin:          #82bc00 !default;
$brand-lima:            #cfde00 !default;
$brand-maiz:            #ffce00 !default;
$brand-tomate:          #ff4338 !default;


//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #F9F9F9 !default;
//** Global text color on `<body>`.
$text-color:            $gray-base !default;

//** Global textual link color.
$link-color:            $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color:      lighten($link-color, 15%) !default;
//** Link hover decoration.
$link-visited: #705582 !default;

$dark-opacity: rgba(0, 0, 0, .5);
$dark-opacity-IE: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000,endColorstr=#99000000);

$shadow: 0 2px 2px rgba(0, 0, 0, .2);

$font-family-serif: 'Droid Serif', serif;
$font-family-sans-serif: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
