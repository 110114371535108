.alert {
    font-weight: 300;
    .btn i {
      color: #fff;
    }     
}

.alert-warning,
.alert-success,
.alert-info,
.alert-danger {
  color: $gray-dark;
}

.alert-warning {
  background: lighten($brand-warning, 33%);
  border-color: lighten($brand-warning, 10%);

  i {
    color: $brand-warning;
  }
}
.alert-success {
  background: lighten($brand-success, 45%);
  border-color: lighten($brand-success, 13%);

  i {
    color: $brand-success;
  }
   
}
.alert-info {
  background: lighten($brand-info, 30%);
  border-color: lighten($brand-info, 10%);

  i {
    color: $brand-info;
  }
}
.alert-danger {
  background: lighten($brand-danger, 35%);
  border-color: lighten($brand-danger, 13%);

  i {
    color: $brand-danger;
  }
}

a.alert {
  &:hover {
    text-decoration: none;
    color: $black;
  }

  &.alert-warning {
    &:hover {
      background: lighten($brand-warning, 28%);
    }

    i {
      color: $brand-warning;
    }
  }
  &.alert-success {
    &:hover {
      background: lighten($brand-success, 40%);
    }

    i {
      color: $brand-success;
    }
  }
  &.alert-info {
    &:hover {
      background: lighten($brand-info, 25%);
    }

    i {
      color: $brand-info;
    }
  }
  &.alert-danger {
    &:hover {
      background: lighten($brand-danger, 30%);
    }

    i {
      color: $brand-danger;
    }
  }
}

@media (max-width: 544px) {
  .alert .fa.fa-fw {
    width: 1em;
  }
}

@media (max-width: 330px) {
  .alert .fa.fa-fw {
    width: 0.7em;
  }
}
