@media print {
  .social-share, 
  .service-rating {
    display: none;
  }  
	.main-footer,
	body.sticky-footer .main-footer {
	  display: none;
	}

}

