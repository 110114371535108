.navbar {
  min-height: 72px;

    &.navbar-top {
      .input-group {
        width: 500px;
        margin-top: 13px;
        margin-left: 30px;

        input {
          height: 46px;
        }
      }
    }

  .navbar-brand {
    padding: 10px 15px;

    img {
      float: left;
      max-width: 100%;
    }

    h1 {
      color: $gray-dark;
      float: left;
      font-size: 30px;
      line-height: 1.4;
      margin: 4px 0 4px 12px;

      strong {
        color: $gray;
      }

      &.brand-2-h1 {
        color: $gray;

        strong {
          color: $brand-primary;
        }
      }
    }

    &.solo {
      h1 {
        margin-top: .7em;
      }
    }
  }

  a.navbar-brand:hover h1 {
    color: $gray;
  }

  .btn.btn-login {
    // margin-left: 0;
    float: right;
  }
}

header {
  .btn {
    margin: 13px 0 0 13px;
    padding: 12px 15px;
  }
  .btn-group > .btn:first-child {
    margin-left: 13px;
  }
}

.navbar-header {
  .duelo {
    margin: 14px 0 0 17px;
  }
}

.navbar-brand {
  height: 72px;
  font-size: 20px;
  font-weight: 500;
  line-height: 2.6;
}

.navbar-brand-2 {
  h1 {
    color: $gray;

    strong {
      color: $brand-primary;
    }
  }
}

.navbar-toggle {
  float: left;
  margin: 20px 0 0 0px;
}

// a {
//   &.btn {
//     line-height: 1.42857143;
//   }
// }

.navbar-default {
  @include position(relative, null null null null);
  background-color: $white;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: $shadow;
  margin: 0;
  z-index: 9;

  .nav > li > a {
    color: $brand-primary;
    font-size: 17px;
    font-weight: 500;
    line-height: 30px;
    padding-bottom: 20px;
    padding-top: 22px;
    text-transform: uppercase;
  }

  .navbar-brand {
    color: $gray-base;

    &:hover {
      color: $gray-base;
    }
  }

  .navbar-nav >  {
    li > a {
      &:hover,
      &:focus {
        background: $brand-primary;
        color: $white;
      }
    }

    .active > a,
    .open>a {
      &,
      &:hover,
      &:focus {
        background: $brand-primary;
        color: $white;
      }
    }

    .btn {
      margin: 13px 0 0 13px;
      padding: 12px 15px;
      float: left;
    }
    .btn-group > .btn:first-child {
      margin-left: 13px;
    }
  }

  .glyphicon-search {
    font-size: 20px;
    top: 4px;
  }

  .navbar-collapse,
  .navbar-form {
    border: 0;
  }

  .navbar-toggle {
    border-color: transparent;

    &:hover,
    &:focus {
      background-color: $brand-primary;

      .icon-bar {
        background-color: $white;
      }
    }
  }
}

.navbar-inverse {
  background-color: $brand-primary;
  border-color: $brand-primary;
  margin: 0;
  min-height: 32px;

  .nav>li>a {
    color: $white;
    font-size: 12px;
    line-height: 12px;
    padding: 8px 15px;
  }

  .navbar-nav>.open>a {
    &,
    &:hover,
    &:focus {
      background-color: $brand-primary;
      color: $white;
    }
  }
}


.navbar-top {
  border-radius: 0;
}

.navbar-error {
  min-height: 70px;

  .navbar-header {
    .navbar-brand {
      float: none;
      margin: 0 auto !important;
      width: 300px;
    }
  }
}

.nav.navbar-social li {
  &.text-muted {
    font-size: 1.4em;
    font-weight: normal;
    line-height: 1.2;
    margin: 1.1em .6em;
    text-transform: none;
  }

  a {
    font-size: 2.9em !important;
    height: 70px;
    line-height: .5;
  }
}

.navbar-form {
  .form-control {
    border: solid 1px $gray-light;
    border-right: 0;
    color: $text-color;
    font-size: 16px;
    height: 49px;
    padding-top: 12px;
    top: 4px;
  }

  .btn {
    margin-top: 4px;
  }
}


.nav-pills {
  > li {

    &.active > a {
      &,
      &:hover,
      &:focus {
        color: $white;
        background-color: $brand-primary;
      }
    }
  }
}


.nav-news {
  padding: 48px 0 40px;

  li {
    float: left;
  }

  a {
    border-radius: 4px;
    color: $brand-primary;
    text-decoration: none;
  }

  .active a {
    &,
    &:hover,
    &:focus {
      background-color: $gray !important;
      color: $white;
      text-decoration: none !important;
    }
  }
}

.nav-news-mini {
  padding: 0 0 24px;
}

.tab-content {
  .tab-pane {
    @include position(relative, null null null null);
  }
}

.nav.nav-icons {
  text-align: center;

  li {
    display: inline-block;
    vertical-align: top;

    a {
      font-weight: 400;
      text-align: center;
      width: 190px;
      vertical-align: top;

      &:hover {
        background: none;
      }

      i {
        display: inline-block;
        width: 80px;
        height: 80px;
        background: $gray;
        color: $white;
        line-height: 2.5;
        font-size: 2em;
        border-radius: 100px;
        margin-bottom: 10px;
        transition: background 0.2s, color 0.2s, opacity 0.2s;

        &.fa {
          line-height: 2.2;
        }
      }

      span {
        font-size: 16px;
        display: block;
        color: $gray;
        transition: color 0.2s;
      }

      &:hover,
      &:focus {
        background: none;

        i {
          opacity: 0.8;
        }
        span {
          color: $gray-dark;
        }
      }
    }
  }

  &.nav-icons-selected li:not( .active ) {
    &:not( :hover ) a:not( :focus ) i {
      background: $gray-light !important;
      color: $gray !important;
    }
    a:hover i,
    a:focus i {
      opacity: 0.7;
      color: $black !important;
    }
  }
}

@media (max-width: 1199px) {

  .navbar.navbar-top .input-group {
    width: 400px;
  }

}

@media (max-width: 992px) {

  .navbar.navbar-top {
    .input-group {
      width: 230px;
    }
  }

  .navbar-brand {
    h1 {
      font-size: 18px;
      margin: 7px 0 7px 12px;
    }
  }

  .navbar-default .nav>li {
    >a {
      font-size: 15px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

}



@media screen and (min-width: 768px) {

  .navbar-right {
    margin-right: 0;
  }

  .navbar-form {
    margin-right: 0;

    .input-group > .form-control {
      width: 284px;
    }
  }

  .navbar-inverse .collapse {
    margin-left: -45px;
    margin-right: -30px;
  }

}

@media screen and (max-width: 767px) {

  .navbar {

    .nav > li > a {
      padding-top: 12px;
      padding-bottom: 10px;
    }

    .btn {
      margin: 13px 0 0 13px;
      padding: 12px 15px;
    }
    .btn-group {
      width:100%;

      > .btn, {
        margin: 13px 0 !important;
        float: none;
        width: 100%;
        border-radius: 4px;
      }
    }

    &.navbar-top {
      .input-group {
        max-width: 100%;
        margin: 13px 0;
        float: left;
        .btn {
          margin-top: 0;
        }
      }

      &.state-search {
        .navbar-header {
          display: none;
        }
        .navbar-nav.navbar-right {
          margin: 0;
          display: block !important;

          > .btn {
            margin: 13px 15px 0 0;
            position: relative;
            z-index: 5;
          }

          .input-group {
            width: auto;
            float: none;
          }
        }
      }
    }
  }

  .navbar-form {
    margin-left: 0;
    margin-right: 0;
  }

  .navbar .navbar-brand {
    padding-right: 0;
  }

  .panel-index .navbar-brand {
    padding: 14px 15px !important;

    h1 {
        font-size: 14px !important;
        margin-left: 0 !important;
      }

    .brand {
      height: 36px;
    }
  }
}

@media (max-width: 490px) {
  .navbar-default {
    min-height: 64px;
  }

  .navbar-toggle {
    margin-top: 15px;
  }

  .navbar {
    .navbar-brand {
      height: 64px;
      padding: 14px 15px;
      line-height: 1.8;

      img {
        height: 36px;
        width: auto;
      }

      h1 {
        font-size: 14px;
        margin-left: 10px;
      }

    }

    .btn {
      margin-top: 9px;
    }

    &.navbar-top {
      .navbar-nav > .btn {
        margin-top: 9px !important;
      }
      .input-group {
        margin: 9px 0;
      }
    }
  }
}

@media (max-width: 420px) {
  .navbar {
    .container {
      // padding: 0 10px;

      .navbar-header {
        .navbar-brand {
          padding: 14px 0 14px;
          width: 160px;
          overflow: hidden;

          img {
            width: 100%;
            max-width: none;
            // height: 32px;
          }

          h1 {
            margin-top: 8px;
            span {display: none;}
          }

          br {
            display: none;
          }
        }
        > .btn {
          padding: 12px;
          margin-left: 10px;
        }
      }
    }
  }
}

@media (max-width: 310px) {
  .navbar .container .navbar-header .navbar-brand {
    width: 120px;
  }
}

