.btn-primary {
  background-color: $brand-primary;

  &:hover,
  &:focus,
  &:active,
  &.focus,
  &.active {
    background-color: lighten($brand-primary, 10%);
  }
}

.btn-success {
  background-color: $brand-success;

  &:hover,
  &:focus {
    background-color: lighten($brand-success, 10%);
  }
}

.btn-info {
  background-color: $brand-info;

  &:hover,
  &:focus {
    background-color: lighten($brand-info, 10%);
  }
}

.btn-warning {
  background-color: $brand-warning;

  &:hover,
  &:focus {
    background-color: lighten($brand-warning, 10%);
  }
}

.btn-danger {
  background-color: $brand-danger;

  &:hover,
  &:focus {
    background-color: lighten($brand-danger, 10%);
  }
}

.btn-link {
  border-radius: 4px;
  color: $brand-primary;
  background-color: $gray-lighter !important;

  &:hover,
  &:focus {
    text-decoration: none !important;
    background-color: $gray-light !important;
    color: $brand-primary;
  }
}

.navbar-default .navbar-nav > .open > .dropdown-toggle {
  &.btn-primary {
    background-color: $brand-primary;
  }
  &.btn-success {
    background-color: $brand-success;
  }
}

.btn-inverse {
  background-color: $gray-hover;
  color: $gray;

  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: $gray-border;
    color: $black;
  }
}

.open > .dropdown-toggle {
  &.btn-inverse {
    background-color: $white;
    color: $brand-primary;
  }
}

.btn-default.disabled,
.btn-default[disabled],
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled],
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
  background-color: $gray-light;
}

.btn-default {
  background-color: $gray;
  color: $white;

  &:hover,
  &:focus {
    background-color: lighten($brand-default, 10%);
    color: $white;
  }
}


.btn {
  border: 0;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  padding: 16px 25px;
  text-transform: uppercase;
  vertical-align: top;
  white-space: initial;
  word-break: initial;
  text-decoration: none;

  &:hover {
    border: 0;
  }

  &.btn-lg {
    font-size: 20px;
    padding: 20px 40px;
  }

  &.btn-sm {
    font-size: 14px;
    padding: 5px 10px;
  }

  &.btn-xs {
    font-size: 12px;
    padding: 1px 5px;
  }

  .fa {
    font-size: 1.2em;

    &.fa-2x {
      font-size: 2em;
    }
    &.fa-3x {
      font-size: 3em;
    }
    &.fa-4x {
      font-size: 4em;
    }
    &.fa-5x {
      font-size: 5em;
    }
  }
}

.input-group-btn {
  .btn {
    margin-top: 0;
  }
}

.btn-xl {
  font-size: 24px;
  line-height: 32px;
  margin-top: 32px;
  padding: 24px 96px;
}

.social-btn {
  a {
    color: $brand-primary;
  }
}

.form-tramite {
  .btn {
    height: 49px;
  }
}

span {
  &.btn {
    &:hover {
      cursor: default;
    }
  }
}

.media .btn {
  white-space: nowrap;
}

.btn-group {
  .btn-default {
    background: $gray-lighter;
    color: $gray-dark;
    border-right: 1px solid $gray-light;

    &:last-of-type {
      border: none;
    }

    &:hover {
      background: $gray-light;
    }

    &.active,
    &:active {
      background: $brand-primary;
      color: $white;
      box-shadow: none;
      cursor: default;
    }
  }

  &.btn-group-vertical {
    .btn {
      border: none;
      border-radius: 4px !important;
    }

    &.btn-group-justified {
      display: block;

      .btn {
        display: block;
        width: 100%;
      }
    }
  }
}
